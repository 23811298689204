import axios from 'axios';
import getToken from '../../getToken';
import { fetchData } from '../fetchData';

const GetGame = async ({
	gpId = [],
	gameCategory = '',
	from = 0,
	limit = 100,
	orderBy = 'desc'
} = {}) => {
	const token = getToken();
	const payload = {
		gpId,
		gameCategory,
		from,
		limit,
		orderBy
	};
	// const response = fetchData(payload, 'getgamelist2');
	// return response;
	return axios
		.post(`${process.env.BASE_URL}/v1/getgamelist2`, payload, {
			headers: {
				Authorization: token
			}
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const GetGameProvider = async () => {
	try {
		const response = await fetchData(null, '/game-provider');
		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const GetPopularGames = async () => {
	try {
		const response = await fetchData(null, 'getPopularGame');
		return response.result;
	} catch (error) {
		throw error?.data;
	}
};

const GetLastPlayedGames = async () => {
	try {
		const response = await fetchData(null, 'lastPlayGames');
		return response;
	} catch (error) {
		throw error?.data;
	}
};

const GetGameUrl = async ({ game, gameLanguage, token }) => {
	try {
		const reqBody = {
			gpId: `${game.gpId}`.toUpperCase(),
			gpGameId: `${game.gpGameId}`,
			gameLanguage
		};
		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/getGameUrl`, {
				method: 'POST',
				body: JSON.stringify(reqBody),
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		throw Error('game not found!');
	}
};

const GetDemoGameUrl = async ({ game }) => {
	const token = getToken();
	try {
		const reqBody = {
			gpId: `${game.gpId}`.toUpperCase(),
			gpGameId: `${game.gpGameId}`
		};
		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/demo-get-game-url`, {
				method: 'POST',
				body: JSON.stringify(reqBody),
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const GetDemoGameUrl2 = async ({ game, token }) => {
	try {
		const reqBody = {
			gpId: `${game.gpId}`.toUpperCase(),
			gpGameId: `${game.gpGameId}`
		};
		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/demo-get-game-url`, {
				method: 'POST',
				body: JSON.stringify(reqBody),
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const GetSBOGameUrl = async ({ token }) => {
	try {
		const reqBody = {
			Portfolio: 'SportsBook'
		};
		const response = await (
			await fetch(`${process.env.BASE_URL}/v1/getGameSbo`, {
				method: 'POST',
				body: JSON.stringify(reqBody),
				headers: {
					Authorization: token
				}
			})
		)?.json();

		return response;
	} catch (error) {
		return error;
	}
};

const SearchGames = async (payload) => {
	try {
		const response = fetchData(payload, 'searchGame');

		return response;
	} catch (error) {
		throw error.response;
	}
	// const token = getToken();
	// try {
	// 	const response = await (
	// 		await fetch(`${process.env.BASE_URL}/v1/searchGame`, {
	// 			method: 'POST',
	// 			body: JSON.stringify(payload),
	// 			headers: {
	// 				Authorization: token
	// 			}
	// 		})
	// 	)?.json();
	// 	return response;
	// } catch (error) {
	// 	return error;
	// }
};

const GameServices = {
	GetGame,
	GetGameUrl,
	GetGameProvider,
	GetDemoGameUrl,
	GetDemoGameUrl2,
	GetSBOGameUrl,
	GetPopularGames,
	GetLastPlayedGames,
	SearchGames
};

export default GameServices;
