import { useState, useCallback } from 'react';
import useWindowHook from './useWindowHook.hook';
import useAuth from '../src/features/auth/useAuth';
import GameServices from '../services/api/games/game.services';
import { toast } from 'react-toastify';
import useModalStore from '../src/features/modal/useModalStore';
import appRoutes from '../src/core/routes';

const useGameActionHook = () => {
	const { isMobile } = useWindowHook();
	const { loginToken, sessionToken } = useAuth();
	const { openModal } = useModalStore((state) => ({ openModal: state.openModal }));

	const [isLoading, setIsLoading] = useState(false);
	const [game, setGame] = useState({
		gameUrl: null,
		gameUrlType: undefined
	});

	const getGameUrlFunction = async (isDemo, game) => {
		let token = loginToken || sessionToken;
		// if (!token) {
		// 	openModal('login'); // Open login modal if no token is found
		// 	return null;
		// }

		const getGameUrlPayload = { gpId: game.pi, gpGameId: game.gi };

		let gameRes = isDemo
			? await GameServices.GetDemoGameUrl2({ game: getGameUrlPayload, token })
			: await GameServices.GetGameUrl({
					game: getGameUrlPayload,
					token,
					language: localStorage.getItem('lang') || 'en-US'
			  });

		if (gameRes?.error) {
			toast.error(gameRes?.message ?? 'Error fetching game data.');
			return null;
		}

		const finalGame = {
			gameUrlType: gameRes?.type ?? undefined,
			gameUrl: isMobile && gameRes.gameUrl.mobile ? gameRes.gameUrl.mobile : gameRes.gameUrl.desktop
		};

		// setGame(finalGame);
		return finalGame;
	};

	const routerGameHtmlUrl = useCallback(
		(gameUrl) => {
			const blob = new Blob([gameUrl.gameUrl], { type: 'text/html' });
			const url = URL.createObjectURL(blob);
			if (isMobile) {
				window.location.replace(url);
				URL.revokeObjectURL(url); // Clean up the blob URL after navigating.
			} else {
				setGame({
					gameUrl: url
				});
			}
		},
		[isMobile]
	);

	const routerGameDefaultUrl = useCallback(
		(gameUrl, game) => {
			if (isMobile) {
				window.location.replace(gameUrl.gameUrl);
			} else {
				if (['HKB', 'AP', 'SBO'].includes(game?.pi)) {
					window.location.replace(gameUrl.gameUrl);
				} else {
					setGame(gameUrl); // Assuming this state is for an iframe or other component.
				}
			}
		},
		[isMobile]
	);

	const launchGameUrl = useCallback(
		async (isDemo, game) => {
			const isDemoNew = Number(isDemo);
			setIsLoading(true);
			try {
				if ((!isDemoNew && !loginToken) || !sessionToken) {
					openModal('login');
					setIsLoading(false);
					return;
				}

				const gameData = await getGameUrlFunction(isDemoNew, game);
				if (!gameData) {
					toast.error('Failed to get game URL.');
					return;
				} // Early exit if there's an error or no game URL.

				if (gameData.gameUrlType === 'htmlContent') {
					routerGameHtmlUrl(gameData, game);
				} else {
					routerGameDefaultUrl(gameData, game);
				}
			} catch (error) {
				setGame({ gameUrl: null, gameUrlType: undefined });
				toast.error('Failed to get game URL. Please try again.');
			} finally {
				setIsLoading(false);
			}
		},
		[routerGameHtmlUrl, routerGameDefaultUrl, loginToken, sessionToken, openModal]
	);

	const gameRouterPush = useCallback(
		(isDemo = 0, game) => {
			if ((!isDemo && !loginToken) || !sessionToken) {
				openModal('login');
				setIsLoading(false);
				return;
			}

			if (isMobile) {
				window.open(
					`${appRoutes.PLAY.LAUNCH_GAME}?gpId=${game?.pi}&gpGameId=${game?.gi}&isDemo=${isDemo}`,
					'_blank'
				);
			} else {
				window.open(
					`${appRoutes.PLAY.GAME}?gpId=${game?.pi}&gpGameId=${game?.gi}&isDemo=${isDemo}`,
					'_blank'
				);
			}
		},
		[isMobile, loginToken, sessionToken, openModal]
	);

	return { gameRouterPush, launchGameUrl, isLoading, game };
};

export default useGameActionHook;
