import { create } from 'zustand';
import dynamic from 'next/dynamic';

const modalComponents = {
	register: dynamic(() => import('./component/RegisterModal')),
	forgot_password: dynamic(() => import('./component/ForgotPasswordModal')),
	login: dynamic(() => import('./component/LoginModal')),
	topup_togel: dynamic(() => import('./component/TopUpBalanceTogelModal')),
	transaction_history: dynamic(() => import('./component/TransactionHistoryModal')),
	change_email: dynamic(() => import('./component/ChangeEmailModal')),
	change_phone_number: dynamic(() => import('./component/ChangePhoneNumberModal')),
	change_password: dynamic(() => import('./component/ChangePasswordModal')),
	update_profile: dynamic(() => import('./component/UpdateProfileModal')),
	verify_account: dynamic(() => import('./component/VerifyAccountModal')),
	add_account: dynamic(() => import('./component/AddAccountModal')),
	deposit: dynamic(() => import('./component/DepositModal')),
	wallet_balance: dynamic(() => import('./component/WalletBalanceModal'))
};

function getModal(modalName) {
	const ModalComponent = modalComponents[modalName];
	return ModalComponent ? <ModalComponent /> : '';
}

const useModalStore = create((set) => ({
	data: {},
	modalIsOpen: false,
	modal: '',
	closeModal: () => {
		document.body.classList.remove('no-scroll');
		return set((state) => ({ ...state, data: {}, modalIsOpen: false }));
	},
	openModal: (modalName, data = {}) => {
		document.body.classList.add('no-scroll');
		return set((state) => ({
			...state,
			data: data,
			modalIsOpen: true,
			modal: getModal(modalName)
		}));
	}
}));

export default useModalStore;
